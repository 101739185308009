import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
  HStack,
  Spinner,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import { PricingCard } from './PricingCard'
import axiosInstance from '../utils/axiosInstance'

const Circle = () => <Box h="4" w="4" bg="white" rounded="full" />

const useSwitch = ({ defaultValue = 'monthly', value, onChange }) => {
  const [internalValue, setInternalValue] = useState(defaultValue)
  const currentValue = value || internalValue
  const isYearly = currentValue === 'yearly'
  const isMonthly = currentValue === 'monthly'

  const handleClick = () => {
    const nextValue = currentValue === 'yearly' ? 'monthly' : 'yearly'
    setInternalValue(nextValue)
    if (onChange) {
      onChange(nextValue)
    }
  }

  return { isYearly, isMonthly, handleClick }
}

const DurationSwitch = (props) => {
  const { isYearly, isMonthly, handleClick } = useSwitch(props)

  const yearlyTextColor = useColorModeValue('blue.600', 'blue.400')
  const monthlyTextColor = useColorModeValue('blue.600', 'blue.400')

  return (
    <HStack
      as="button"
      fontSize="xl"
      fontWeight="semibold"
      spacing="4"
      display="inline-flex"
      onClick={handleClick}
    >
      <Text color={isYearly ? yearlyTextColor : undefined}>Annual</Text>
      <Flex
        align="center"
        justify={isYearly ? 'flex-start' : 'flex-end'}
        height="5"
        w="35px"
        rounded="full"
        px="2px"
        bg="blue.600"
      >
        <Circle />
      </Flex>
      <Text color={isMonthly ? monthlyTextColor : undefined}>Monthly</Text>
    </HStack>
  )
}

const PricingBlock = () => {
  const [billingCycle, setBillingCycle] = useState('monthly')
  const [pricingPlans, setPricingPlans] = useState(null) // Initialize as null to indicate loading state
  const [error, setError] = useState(null) // State to handle API errors

  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        const response = await axiosInstance.get('/weblang/api/payments/new')
        setPricingPlans(response.data.packages)
      } catch (error) {
        console.error('Error fetching pricing plans:', error)
        setError('Failed to load pricing plans. Please try again later.')
      }
    }
    fetchPricingPlans()
  }, [])

  // Check if the data is still loading
  if (!pricingPlans) {
    return (
      <Box textAlign="center" py="6">
        {error ? (
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        ) : (
          <>
            <Spinner size="xl" />
            <Text mt="4">Loading Pricing Plans...</Text>
          </>
        )}
      </Box>
    )
  }

  // Map the correct price and construct the payment URL based on billing cycle
  const plansToDisplay = pricingPlans.map((plan) => {
    const isFreePlan = plan.name === 'Free'
    const paymentUrl = isFreePlan ? '/weblang/editor_install' :
      (billingCycle === 'yearly' ? plan.yearly_payment_url : plan.monthly_payment_url)

    return {
      ...plan,
      paymentUrl, // Add payment URL based on the selected billing cycle or Free plan URL
    }
  })

  return (
    <Box as="section" py="1">
      <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto">
        <Flex align="center" justify={{ base: 'center', lg: 'flex-center' }} mb="5">
          <DurationSwitch
            defaultValue="monthly"
            onChange={(value) => setBillingCycle(value)}
          />
        </Flex>
        <SimpleGrid mt="5" columns={{ base: 1, lg: 3 }} spacing="10">
          {plansToDisplay.map((plan, index) => (
            <PricingCard
              key={index}
              bg={['gray.50', 'blue.600', 'gray.700'][index]}
              color={index === 0 ? 'black' : 'white'}
              button={
                <Button
                  w="full"
                  h="16"
                  colorScheme={index === 0 ? 'blue' : 'gray'}
                  variant={index === 0 ? 'outline' : 'solid'}
                  size="lg"
                  locked={plan.locked}
                  isDisabled={!plan.paymentUrl} // Disable the button if the payment URL is null
                  onClick={() => {
                    if (plan.paymentUrl) {
                      window.location.href = plan.paymentUrl // Redirect to the dynamic payment URL or free plan URL
                    }
                  }}
                >
                  {plan.name === 'Free' ? 'Start for Free' : 'Select'}
                </Button>
              }
              data={{
                ...plan,
                duration: billingCycle
              }}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

export default PricingBlock
