import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Textarea,
  useColorModeValue as mode,
  Heading,
  HStack,
  Text,
  Flex,
  Spinner,
  Alert,
  AlertIcon, IconButton,
} from '@chakra-ui/react'
import { HiArrowLeft } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom'
import { Header } from '../../components/Header'
import axiosInstance from '../../utils/axiosInstance'

const EditTranslation = () => {
  const { languageId } = useParams() // Get language ID from URL params
  const navigate = useNavigate()
  const [translations, setTranslations] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [alertMessage, setAlertMessage] = useState('') // Alert for save success or failure
  const [alertStatus, setAlertStatus] = useState('success') // To handle success or error alerts
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 50 // Show 50 records per page

  // Fetch translations from the API
  useEffect(() => {
    const fetchTranslations = async () => {
      try {
        const response = await axiosInstance.get(`/weblang/api/languages/${languageId}/page_translations`)
        setTranslations(response.data)
        setLoading(false)
      } catch (error) {
        setError('Failed to load translations.')
        setLoading(false)
      }
    }

    fetchTranslations()
  }, [languageId])

  const handleInputChange = (id, value) => {
    setTranslations((prevTranslations) =>
      prevTranslations.map((translation) =>
        translation.id === id
          ? { ...translation, translation: value, edited: true }
          : translation
      )
    )
  }

  const handleSave = async (id) => {
    const translationToUpdate = translations.find((translation) => translation.id === id)
    try {
      // Send a PUT request to update the translation
      await axiosInstance.put(`/weblang/api/languages/${languageId}/page_translations/${id}`, {
        translation: translationToUpdate.translation,
      })
      setAlertMessage('Translation saved successfully.')
      setAlertStatus('success')

      // Mark translation as not edited after saving
      setTranslations((prevTranslations) =>
        prevTranslations.map((translation) =>
          translation.id === id ? { ...translation, edited: false } : translation
        )
      )
    } catch (error) {
      setAlertMessage('Failed to save the translation. Please try again.')
      setAlertStatus('error')
    }
  }

  const totalPages = Math.ceil(translations.length / itemsPerPage)
  const paginatedTranslations = translations.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  return (
    <Box bg={mode('gray.100', 'gray.700')} minHeight="100vh">
      <Box maxW="7xl" mx="auto" px={{ base: '6', md: '8' }}>
        {/* Back Arrow and Heading */}
        <HStack>
          <IconButton
            icon={<HiArrowLeft />}
            aria-label="Back to Languages"
            onClick={() => navigate('/weblang/languages')} // Use react-router-dom navigate hook
            mr={1}
          />
          <Heading size="lg">Manage Translations</Heading>
        </HStack>
      </Box>

      <Box as="section" py="6">
        <Box maxW="7xl" mx="auto" px={{ base: '6', md: '8' }}>
          {/* Error or success alerts */}
          {alertMessage && (
            <Alert status={alertStatus} mb="6">
              <AlertIcon />
              {alertMessage}
            </Alert>
          )}

          {/* Show loading spinner */}
          {loading ? (
            <Box textAlign="center" py="10">
              <Spinner size="xl" />
              <Text mt="4">Loading Translations...</Text>
            </Box>
          ) : (
            <TableContainer bg="white" borderRadius="md">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>English</Th>
                    <Th>Translation</Th>
                    <Th>Path</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {paginatedTranslations.map((translation) => (
                    <Tr key={translation.id}>
                      {/* Original English text grows vertically as needed */}
                      <Td maxWidth="400px" whiteSpace="normal" wordBreak="break-word">
                        {translation.text}
                      </Td>
                      <Td>
                        {/* Translation field as a Textarea */}
                        <Textarea
                          value={translation.translation}
                          onChange={(e) =>
                            handleInputChange(translation.id, e.target.value)
                          }
                          rows={3}
                        />
                      </Td>
                      <Td>{translation.page_path}</Td> {/* Display the path */}
                      <Td>
                        <Button
                          colorScheme="blue"
                          size="sm"
                          isDisabled={!translation.edited}
                          onClick={() => handleSave(translation.id)}
                        >
                          Save
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}

          {/* Pagination Controls */}
          <Flex mt={4} justify="center">
            <Button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              isDisabled={currentPage === 1}
            >
              Previous
            </Button>
            <Text mx={4} alignSelf="center">
              Page {currentPage} of {totalPages}
            </Text>
            <Button
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              isDisabled={currentPage === totalPages}
            >
              Next
            </Button>
          </Flex>
        </Box>
      </Box>
    </Box>
  )
}

export default EditTranslation
