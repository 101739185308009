// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WeblangHelp from './pages/Weblang/Help';
import WeblangInstall from './pages/Weblang/Install';
import WeblangPayment from './pages/Weblang/Payment';
import EditorInstall from './pages/Weblang/EditorInstall';
import WeblangLanguages from './pages/Weblang/Languages';
import WeblangCreateLanguages from './pages/Weblang/CreateLanguages';
import WeblangEditTranslation from './pages/Weblang/EditTranslation';
import WeblangMultilingualIndex from './pages/WeblangMultilingual/Index';
import instanceMiddleware from './middleware/instanceMiddleware';
import WeblangLayout from './layouts/WeblangLayout';

const App = () => {
  useEffect(() => {
    instanceMiddleware();
  }, []);

  return (
    <Router>
      <Routes>
        {/* Parent route with WeblangLayout that contains child routes */}
        <Route element={<WeblangLayout />}>
          {/* All Weblang pages */}
          <Route path="/weblang/install" element={<WeblangInstall />} />
          <Route path="/weblang/payment" element={<WeblangPayment />} />
          <Route path="/weblang/editor_install" element={<EditorInstall />} />
          <Route path="/weblang/languages" element={<WeblangLanguages />} />
          <Route path="/weblang/languages/new" element={<WeblangCreateLanguages />} />
          <Route path="/weblang/:languageId/translations" element={<WeblangEditTranslation />} />
          <Route path="/weblang/help" element={<WeblangHelp />} />
        </Route>

        <Route path="/weblang_multilingual" element={<WeblangMultilingualIndex />} />
        <Route path="/" element={<div>Welcome to Rubyroid Tech UI</div>} />
      </Routes>
    </Router>
  );
};

export default App;
