import React, { useState, useEffect } from 'react';
import { Box, Button, Spinner, useColorModeValue as mode } from '@chakra-ui/react';
import { Header } from '../components/Header';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';

const WeblangLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [balance, setBalance] = useState(null);
  const [loadingBalance, setLoadingBalance] = useState(true);

  // Pages that are part of the install flow where we don't want to redirect
  const installFlowPages = ['/weblang/install', '/weblang/payment', '/weblang/editor_install'];

  // Fetch balance from the payments API
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axiosInstance.get('/weblang/api/payments/balance');
        setBalance(response.data.balance);
        setLoadingBalance(false);

        // Redirect to the install page if the balance is null or 0 and we're not on an install flow page
        if ((response.data.balance === null || response.data.balance === 0) &&
          !installFlowPages.includes(location.pathname)) {
          navigate('/weblang/install');
        }
      } catch (error) {
        console.error('Failed to fetch balance:', error);
        setLoadingBalance(false);

        // If there's an error, redirect to the install page if we're not on an install flow page
        if (!installFlowPages.includes(location.pathname)) {
          navigate('/weblang/install');
        }
      }
    };
    fetchBalance();
  }, [navigate, location.pathname]); // Include location.pathname in the dependency array

  // Links for the header navigation
  const links = [
    { label: 'Languages', href: '/weblang/languages' },
    { label: 'Billing', href: '/weblang/payment' },
    { label: 'Help', href: '/weblang/help' },
  ];

  // Determine if we are on the /weblang/install page
  const hideLinksAndBalance = location.pathname === '/weblang/install';

  return (
    <Box>
      <Header
        logo="/weblang_logo.png"
        links={hideLinksAndBalance ? [] : links} // Hide links on /weblang/install
        activeLink={location.pathname}
        actionsComponent={
          !hideLinksAndBalance &&
          (loadingBalance ? (
            <Spinner size="md" />
          ) : (
            <Button colorScheme="blue" rounded="full">
              Balance: {balance?.toFixed(0)} words
            </Button>
          ))
        }
      />
      <Box as="main" py="6" px="4" bg={mode('gray.100', 'gray.700')} minHeight="calc(100vh - 65px)">
        <Outlet />
      </Box>
    </Box>
  );
};

export default WeblangLayout;
