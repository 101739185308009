import { Box, Flex, LightMode, ListItem, Text, UnorderedList, Icon } from '@chakra-ui/react'
import React from 'react'
import { FiLock } from 'react-icons/fi'

interface PricingCardProps {
  button: React.ReactElement
  data: {
    name: string
    description: string
    monthly: number
    yearly: string
    features: string[]
    duration: 'monthly' | 'yearly'
    tag?: string  // Add billing badge text as a prop
    locked?: boolean  // Add locked property
  }
}

// BillingBadge now accepts text from outside via props
const BillingBadge = ({ text }: { text: string }) => (
  <Box
    rounded="full"
    fontSize="sm"
    bg="white"
    color="gray.900"
    px="3"
    py="1"
    pos="absolute"
    top="4"
    right="4"
    fontWeight="bold"
  >
    {text}
  </Box>
)

export const PricingCard = (props: PricingCardProps) => {
  const { button, data, ...rest } = props
  const { name, description, monthly, duration, features, tag, yearly, locked } = data
  const isFree = monthly === 0

  return (
    <Flex
      direction="column"
      px="6"
      py="8"
      rounded="lg"
      pos="relative"
      maxW="2xl"
      mx="auto"
      overflow="hidden"  // Ensure the overlay doesn't overflow the card
      {...rest}
    >
      {/* Lock overlay: Only show if the plan is locked */}
      {locked && (
        <Box
          pos="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="rgba(0, 0, 0, 0.8)"  // Semi-transparent black overlay
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          opacity="1"
          transition="opacity 0.3s ease"
          _hover={{ opacity: 0 }}  // Fade out on hover
          zIndex="5"
        >
          <Icon as={FiLock} boxSize={12} color="white" />
          <Text fontSize="md" lineHeight="1" color="white" fontWeight="bold" textAlign="center" mt="3">Selected languages exceed plan limit</Text>
        </Box>
      )}

      <Box flex="1">
        {/* Show the billing badge if it's not a free plan */}
        {tag && <BillingBadge text={tag} />}

        <Text fontSize="2xl" lineHeight="1" fontWeight="bold">
          {name}
        </Text>

        <Flex align="center" fontWeight="extrabold" mt="4" mb="3">
          <Text fontSize={{ base: '6xl', xl: '7xl' }} lineHeight="1" flexShrink={0}>
            ${duration === 'monthly' ? monthly : yearly}
          </Text>
          {!isFree && (
            <>
              <Box mx="4">
                <Box w="2px" h="12" bg="white" opacity={0.5} transform="rotate(15deg)" />
              </Box>
              <Text fontSize="xl" lineHeight="1.2">
                per <br /> {duration === 'monthly' ? 'month' : 'year'}
              </Text>
            </>
          )}
        </Flex>

        {/* Annual amount passed from outside */}
        <Box>{isFree ? <>&nbsp;</> : `$${yearly} billed annually`}</Box>

        <Box mt="6">
          <Text fontSize="xl" fontWeight="semibold" mb="6">
            {description}
          </Text>
          <UnorderedList spacing="3">
            {features.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Box>
      <Box mt="10" zIndex="2">  {/* Ensure button is on top of the overlay */}
        <LightMode>{button}</LightMode>
      </Box>
    </Flex>
  )
}
